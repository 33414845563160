import Search, {SearchSize} from "@/Atoms/Search/Search";
import styles from './Hero.module.scss'
import {FormEvent, useState} from "react";

export default function Hero() {

  const [searchValue, setSearchValue] = useState('')
  const handleSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    location.href = `/overlijdensberichten?lastName=${searchValue}`
  }

  return <section className={`bg-beige ${styles.hero}`} >
    <div className={styles.videoBackground}>
      <div className={styles.content}>
        <h1 className="section__title">Samen leven, samen delen.</h1>
        <p className="section__subtitle">Voor eeuwig en altijd hun verhaal.</p>
      </div>

      <video playsInline muted autoPlay loop>
        <source src="/header-movie.mp4" type="video/mp4"/>
      </video>
    </div>
    <div className={styles.searchContainer}>

      <form className={styles.searchForm} onSubmit={handleSearch}>
        <Search size={SearchSize.Large}
                value={searchValue}
                onChange={(input) => setSearchValue(input)}/>
      </form>
        <div className={styles.search}>

          <a href="/overlijdensberichten/" className="inline-block my-5 link">Ik wil uitgebreid zoeken <i
            className="fa-solid fa-angle-right"></i></a>
        </div>
    </div>
  </section>
}
