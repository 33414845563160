
import Tile from "@/Molecules/Article/Tile";
import {Article} from "@/types/Article.d";
import TileList from "@/Molecules/Article/TileList";


export default function BlogsLatest({articles}: {articles: Article[]}) {
  return <TileList>
          {articles.map((article, index) => <Tile key={index} article={article} baseUrl={'/in-memoriam'}/>)}
        </TileList>
}
