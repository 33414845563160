
import styles from './Obituaries.module.scss'
import {Person} from "@/types/person";
import {InteractionStats} from "@/types/interactionStats";
import Card from "@/Molecules/Obituary/Card";
import Commercial, {CommercialType} from "@/Atoms/Commercial";


export default function ObituaryOverview({obituaries}: {obituaries: []}) {

  return <div className={[styles.obituaries].join(' ')}>
        {obituaries.map((obituary: {person: Person, stats: InteractionStats}, index) => {
          return <div key={index}>
            <Card key={index} person={obituary.person} stats={obituary.stats}/>
          </div>
        })}
      </div>

}
